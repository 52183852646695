import combined1 from "../assets/combined1.jpg";
import combined2 from "../assets/combined2.jpg";
import combined3 from "../assets/combined3.jpg";
import amsterdam1 from "../assets/amsterdamtour1.jpg";
import amsterdam2 from "../assets/amsterdamtour2.jpg";
import amsterdam3 from "../assets/amsterdamtour3.jpg";
import mill1 from "../assets/mill1.jpg";
import mill2 from "../assets/mill2.jpg";
import mill3 from "../assets/mill3.jpg";
import century1 from "../assets/century1.jpeg";
import century2 from "../assets/century2.jpeg";
import century3 from "../assets/century3.jpeg";
import boat1 from "../assets/boat1.jpg";
import boat2 from "../assets/boat2.jpeg";
import boat3 from "../assets/boat3.jpg";
import flower1 from "../assets/flower1.jpg";
import flower2 from "../assets/flower2.jpg";
import flower3 from "../assets/flower3.jpg";
import canalboat1 from "../assets/canalboat1.jpg";
import canalboat2 from "../assets/canalboat2.jpg";
import canalboat3 from "../assets/canalboat3.jpg";
import bike1 from "../assets/bike1.jpg";
import bike2 from "../assets/bike2.jpg";
import bike3 from "../assets/tour2.jpeg";
import quest1 from "../assets/quest1.jpeg";
import quest2 from "../assets/quest2.jpg";
import quest3 from "../assets/quest3.jpg";
import nightamsterdam1 from "../assets/nightamsterdam1.jpg";
import nightamsterdam2 from "../assets/nightamsterdam2.jpg";
import nightamsterdam3 from "../assets/nightamsterdam3.jpeg";
import tasty1 from "../assets/tasty1.jpg";
import tasty2 from "../assets/tasty2.jpg";
import tasty3 from "../assets/tasty3.jpg";
import tasting1 from "../assets/tasting.jpg";
import tasting2 from "../assets/tasting1.jpg";
import tasting3 from "../assets/tasting2.jpg";

const cardData = [
  {
    title: "Хотите всё сразу? Комбинированный Тур",
    text: [
      " Прогулка по старому городу или йорданскому району - 2 часа.",
      " Фотосессия в национальной одежде.",
    ],
    images: [combined1, combined2, combined3],
    additionalInfo: `Это - наш Комбинированный Тур! Начнём с увлекательной прогулки по выбранной части города: старому городу или йорданскому району. Продолжительность - приятные 2 часа.
    Программа включает возможность переодеться в национальную одежду и сделать уникальные фотографии в деревенской атмосфере. После прогулки мы насладимся перекусом из селёдки, а затем отправимся на приватную лодку, чтобы увидеть Амстердам с воды. Поверьте, его красота неповторима и восхитительна. Вы можете взять с собой любимые напитки и закуски для путешествия на лодке. И если у вас есть повод, мы даже можем украсить лодку по вашему желанию!
    Лодка забронирована исключительно для вашей группы до 8 человек. И самое интересное: стоимость тура указана за всю группу, а не за каждого человека! Дополнительно, вы можете посетить дом на воде, расположенный на канале, чтобы взглянуть на жизнь на воде изнутри. Стоимость этой дополнительной экскурсии - 5 евро с человека.`,
  },
  {
    title: "Это Амстердам детка",
    text: [
      " Отличные места для дегустации, особенности площади Дам и знаменитых кварталов.",
      " Продолжительность: 2 часа.",
    ],
    images: [amsterdam1, amsterdam2, amsterdam3],
    additionalInfo: `Города, подобно людям, способны произвести первое впечатление всего один раз. Чтобы Амстердам для вас не остался лишь типичным европейским городом с узкими улочками и богатой историей, я с удовольствием вас проведу и познакомлю с яркими символами столицы.

Вы познакомитесь с тюльпанами, велосипедами, каналами и голландскими сырами, перенося представление об Амстердаме в его реальный облик. Узнаете, где находятся лучшие места для дегустации, особенности площади Дам и знаменитых кварталов. И, в заключение, отправитесь на пароме к самым высоким качелям Европы! Индивидуальная Экскурсия для 1–4 Человек.Длительность: 2 Часа
.Подходит для детей. Пешеходная экскурсия. Включает в себя дегустацию вафель, сырных цветов с вином.`,
  },
  {
    title: "Уникальная деревня мельниц",
    text: [
      " Уникальная деревня: Занзе Сханс, сырная ферма, музей обуви.",
      " Прекрасные Мельницы: река Заан.",
    ],
    images: [mill1, mill2, mill3],
    additionalInfo: `Это уникальная деревня, где можно увидеть маленькие пряничные домики, посетить сырную ферму и погрузиться в мир национальной деревянной обуви. Но настоящей изюминкой являются рабочие мельницы, удивительно прекрасные и уникальные. Все это расположено вдоль реки Заан, и никакие слова или фотографии не смогут передать их неповторимой красоты.

Место переполнено эмоциями и разнообразными активностями. Вход в деревню бесплатный, но уникальная Мельница Кат оставляет незабываемые впечатления. Именно здесь производят пигмент для красок, а также здесь можно подняться на прекрасную обзорную площадку и полюбоваться красотой деревни.

Экскурсия займёт 3 часа. Дорожные расходы составляют 7.50 евро на человека, а вход в мельницу стоит 5 евро. Группы до 4 человек могут принимать участие, и это мероприятие подходит для посещения с детьми. Здесь можно не только увидеть мельницы, но и попробовать местные вафельки с кофе, поучаствовать в процессе изготовления деревянной обуви, насладиться великолепными видами и отведать местного сыра. Это будет день, наполненный невероятными эмоциями.`,
  },
  {
    title: "Фотосессия в стиле нидерландского жителя 17 века.",
    text: [
      " Фотосессия в старинных костюмах",
      " Дегустация в зале De Drie Fleschjes.",
    ],
    images: [century1, century2, century3],
    additionalInfo: `Часто посещая Амстердам с друзьями, хочется чего-то уникального. Предлагаем заказать фотосессию в старинных костюмах Золотого века после осмотра города и увлекательных рассказов о жизни нидерландцев.

Фотостудия запечатлеет вас как шедевр, который будете выбирать сами. Вы получите 1 фото в рамке, 1 в виде открытки и еще 1 по эл. почте (до 6 человек на фото). Затем, после веселой фотосессии, дегустация в зале De Drie Fleschjes, существующем 365 лет, с интерьером, не изменившимся со времен Золотого века.

Попробуйте 3 напитка с оригинальными названиями, отражающими историю. Дегустация сопровождается рассказами об истории и напитках того времени.

Индивидуальная экскурсия для 1–6 человек, длительностью 3 часа, пешком.`,
  },

  {
    title: "Кекенхоф: Цветы, Искусство, Незабываемый Опыт.",
    text: [" Кекенхоф: 32 га тюльпанов.", " 800+ сортов цветов."],
    images: [flower1, flower2, flower3],
    additionalInfo: `Кекенхоф, известный как самый красивый весенний парк, удивляет 32 гектарами тюльпанов и цветов. Парк проложен 15-километровой пешеходной дорожкой, ведущей к водоемам, фонтанам и скульптурам. С 800+ сортами цветов, дети наслаждаются игровой площадкой Miffy и веселой охотой за сокровищами.

Расположенный в сердце Болленстрика, Кекенхоф предлагает виды на поля с тюльпанами, нарциссами и гиацинтами. Парк украшен арт-объектами художников и создан оригинальным дизайном отцом и сыном Зохером в 1865 году.

Туры для 1-6 человек с минивеном, длительностью 5 часов (с дорогой), вход не включен в стоимость. Предлагается ценовая политика без машины.`,
  },
  {
    title: "Человек пароход. Прогулка на лодке",
    text: [
      " Прогулка по каналам: закуски.",
      " Виды с лодки: уникальные детали Амстердама.",
    ],
    images: [canalboat1, canalboat2, canalboat3],
    additionalInfo: `Если у вас особенное событие, то прогулка по каналам Амстердама — это непревзойденный опыт, фантастический взгляд на город с воды. На борт можно взять закуски и напитки, а также по желанию тематически оформить лодку. Открытая лодка позволяет увидеть самые мелкие детали окружения, водные полицейские и правила движения будут подробно объяснены. А самые маленькие смогут даже попробовать управлять лодкой.`,
  },

  {
    title: "А какой он ночью ?",
    text: [
      " Необычное путешествие от церкви.",
      " Запретные места, морская традиция.",
    ],
    images: [nightamsterdam1, nightamsterdam2, nightamsterdam3],
    additionalInfo: `Приглашаю в увлекательное путешествие! Начнем от церкви, но маршрут будет необычным. Мы заглянем в места, где обычно не разрешают заходить. Одна из задач — выпить, как моряки делали это традиционно. Позже узнаем, в чем разница между разными магазинами. Будем находиться в местах, где традиционно курят трубку мира. А затем нас ждут странные шоу и красные окна. А завершим мы это удивительное приключение в баре старого моряка. Там нас ждет кружка пива и история о том, зачем морякам были нужны вшивые обезьянки.`,
  },
  {
    title: "Вкусный тур",
    text: [" Гастрономический город", " Рестораны, уличная еда."],
    images: [tasty1, tasty2, tasty3],
    additionalInfo: `В нашем городе куда ни посмотри — всюду палатки и магазинчики со всевозможной снедью, атмосферные кафе и видовые рестораны. Вот янтарные круги сыра лежат аккуратной стопкой, прямо как в домике Бильбо Беггинса. Вот пыхтит над увесистыми стейками матёрый повар. Здесь селедочку продают с маринованными огурчиками. А там — ароматные блины с разными начинками и традиционные карамельные вафли. Амстердам — это не только красиво, но и вкусно! И на прогулке я вам это с радостью докажу!`,
  },
  {
    title: "Дегустация в старом трактире.",
    text: [
      " Дегустация в зале Золотого века.",
      " История, напитки, атмосфера.",
    ],
    images: [tasting1, tasting2, tasting3],
    additionalInfo: `После фотосессии вы отправитесь в дегустационный зал De Drie Fleschjes, который восходит к Золотому веку. Этот зал существует уже 365 лет, и его интерьер почти не изменился. Здесь вы найдете классический шарман и коллекцию бутылок мэра. Вы сможете попробовать 3 напитка, такие как 'Экскурсия по церкви' или 'Саскии' (жены Рембрандта). Я также расскажу вам о истории и напитках того времени.`,
  },
  {
    title: "По Амстердаму играючи: прогулка-квест.",
    text: [" Игровое изучение города.", " Интересный, интерактивный тур."],
    images: [quest1, quest2, quest3],
    additionalInfo: `Давайте исследуем город через квесты! Вместе откроем уникальные места и исторические достопримечательности. Я придумаю загадки и буду вести вас сквозь каждое задание.

Вас ждут задания: переплыть реку, мгновенно забежать на фильм без просмотра, найти здание на 8000 сваях, стоять возле плачущей башни, отыскать дома по табличкам, взглянуть на сгоревший дом, попробовать сыр с лавандой, увидеть красный огонёк в окне и без страха прогуляться по кладбищу.

Всего за 3 часа вы узнаете и запомните город лучше, чем за целый день утомительных рассказов. И помните, самый энергичный участник получит нидерландский приз! Если участников будет меньше 6, заменим игру обзорной экскурсией — так веселее. Подробности об альтернативной прогулке можно обсудить позже.`,
  },
  {
    title: "Прогулка на велосипедах, исследуя Амстердам вместе.",
    text: [" Велосипеды: каналы, музеи", " Экскурсия: история, гастрономия."],
    images: [bike1, bike2, bike3],
    additionalInfo: `Присоединяйтесь ко мне на велотуре по Амстердаму! Начнем на Центральном вокзале, проедем вдоль каналов к красивым мостам и панорамным видам. Посетим крупнейший научный музей, увидим корабль 18 века и погрузимся в средневековые истории на площади.

Посетим самый большой блошиный рынок в Нидерландах с сокровищами от античности до наших дней. Проедем мимо цветочного рынка, заглянем на Площадь Рембрандта, узнаем о знаменитой бронзовой инсталляции 'Ночной дозор'.

Проехав через центр города, увидим Площадь Дам с иконичным дворцом на 14 000 сваях и старейшей церковью с великим органом.

Маршрут пройдет мимо монастыря, где я поделюсь удивительными фактами о религиозном движении. Тур завершится на Центральном вокзале, где можно вернуть велосипед. По желанию можно пригласить фотографа для запечатления этого приключения!`,
  },
  {
    title: "Персональная яхта.",
    text: [
      " Приватная 2-часовая прогулка.",
      " Остров Пампус: старинный морской форт мистической красоты.",
    ],
    images: [boat1, boat2, boat3],
    additionalInfo: `Если важное событие на подходе и хочется провести время с друзьями, предлагаем уникальную возможность: приватная 2-часовая прогулка на острове Пампус. Это загадочное место – искусственный остров с морским фортом, частью оборонительной системы возле Алмере.

Форт, начатый в 1887 году и сданный в эксплуатацию через 8 лет, служил обороной входа в гавань. Спустя время форт передали гражданским властям, отреставрировали и открыли для посещений. Большая часть помещений сохранила оригинальную обстановку, привлекая туристов.

Волонтёры устраивают квесты на пиратскую тему, привлекая более 40 тысяч искателей приключений ежегодно. Однако, большая часть боевой артиллерии была убрана с острова по королевскому приказу.`,
  },
];

export default cardData;
