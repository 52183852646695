const dentalDataCopy = {
  p1: `
    Если вы кликнули на эту страницу скорей всего вам нужна помощь
    стоматолога или имплантолога. Прежде чем показать вам
    компетентность и рассказать все с профессиональной
    имплантологии я бы хотела начать со своей настоящей истории.
  `,
  p2: `
    Два года назад у меня отвалился мост в верхней части челюсти.
    Я пошла в Амстердаме к стоматологу и она сказала мне, что на
    место приклеить нельзя надо ставить протез на всю верхнюю
    челюсть. Я испугалась что в таком молодом возрасте носить
    протез это конец всего и я сильно переживала. 
    
  `,
  p3: `
    Это был АД я не могла дышать, разговаривать и постоянно нужно было использовать клей, чтобы зубы держались. Я просто была в полной депрессии, потом мне сделали протез и сказали все будет хорошо все привыкли и ты привыкнешь. 
    
  `,
  p4: `
    Целый год я тратила деньги и время, и в результате осталась без зубов, стеснялась улыбаться, разговаривать и постоянно прикрывала рот. 
    
  `,
  p5: `
    Я перестала пережевывать еду и это сильно сказалось на моем желудке. Поняв, что больше у меня нет сил и что я никогда не привыкну к этому состоянию я собрала немного денег и поехала к моему спасителю в Турцию. Мне очень повезло это был не имплантолог, а волшебник. Поездка была двух этапной. Сначала мне подготовили десна и челюсть, сделали все исследования.
    
  `,
  p6: `
    А второй этап состоялся через 4-5 месяцев когда уже мне поставили зубы. Процедура длилась 5 часов, но оно того стоило. 
    
  `,
  p7: `
    Мне подали зеркало и я первый раз улыбнулась. Боже как это круто есть, что-то что хрустит или улыбается не прикрывая рот. Я была переполнена эмоциями и даже заплакала. 
    
  `,
  p8: `
    А еще бонусом я пока ждала примерки, смогла съездить на лыжный курорт Эрджияс и покататся на воздушном шаре в Каппадокии эти две локации оказались очень близко друг другу и это был приятный бонус к моей поездке. Вернулась я уже совсем другим человеком. Не буду описывать весь профессионализм моего доктора и клиники. Но все мои страхи просто закончились. Я знаю как ВЫ мучаетесь и точно знаю что в этой жизни все можно исправить, а зубы и подавно.
    
  `,
};

export default dentalDataCopy;
