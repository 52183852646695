import React from "react";

const dentalDataCopy = {
  p1: (
    <React.Fragment>
      <b>Стоматологический туризм</b> – это когда человек едет в другую страну
      для лечения зубов. Оздоровительный и стоматологический туризм имеет особое
      значение для каждой страны.
    </React.Fragment>
  ),
  p2: (
    <React.Fragment>
      У людей может быть много разных причин для обращения за медицинской
      помощью в другой стране. Например, качество и цены медицинских услуг в
      одних странах могут быть выше, чем в других странах, и поэтому люди могут
      не захотеть <b>получать медицинскую помощь</b> в этих странах. Кроме того,
      в некоторых странах могут отсутствовать определенные медицинские услуги,
      поэтому людям, возможно, придется ездить в другие страны, чтобы получить
      эти услуги.
    </React.Fragment>
  ),
  p3: (
    <React.Fragment>
      В результате у людей может <b>быть множество причин</b> обращаться за
      медицинской помощью в другую страну. Эти причины могут включать такие
      факторы, как качество, <b>цена, недоступные услуги и время ожидания.</b>
    </React.Fragment>
  ),
  p4: (
    <React.Fragment>
      <b>Стоматологический туризм</b> — это термин, используемый для людей,
      которые выезжают за границу для получения стоматологических услуг.
      Стоматологический туризм обычно осуществляется в странах, которые
      предлагают более дешевые и качественные услуги, и эти услуги могут
      включать:
    </React.Fragment>
  ),
  p5: `
    Эстетическая стоматология располагает широкимспектром методов и
приемов восстановленияестественной формы зубов, их цвета и
положения в зубном ряду. Специалисты стоматологической
клиникиопределяют подходящий метод работы в каждом конкретном
случае с учётом общего состояния зубов пациента, вида дефекта,
особенностей прикуса и ряда других факторов.
Сегодня все больше зубов восстанавливают винирами,которые
выглядят максимально естественно, не меняют цвет и не стираются. 
  `,
  p6: `
    Основными преимуществами имплантации зубов являются
эстетической результат, долговечность и отсутствие дискомфорта.
Установка зубных имплантов это наиболее рациональный метод
восстановления отсутствующих зубов, позволяющий замещать
дефекты любых размеров без нарушения целостности соседних зубов.
Во многих случаях стоимостьустановки зубных имплантов не
превышает стоимости альтернативных методов лечения.
  `,
  p7: `
    Для восстановления полностью отсутствующих зубов или зубов, неподлежащих лечению в челюсть пациента вживляют 6 или 4 импланта, на которые в последующем фиксируется протез (весь зубной ряд). Протезирование на 6 имплантах, как и на 4 имплантах, гарантирует эстетический результат. Количество имплантов и выбор методики зависят от индивидуальных особенностей пациента и рекомендаций стоматолога-имплантолога для достижения оптимальной функциональности и эстетики зубного ряда.
  `,
  p8: (
    <React.Fragment>
      Помимо того, что вы получаете полный пакет услуг связанных с вашими зубами
      этот вид туризма подразумевает полное сопровождения, трансфер и проживания
      в отеле и конечно же чтобы не терять время в ожидании путешествия в стране
      прибывания организация вашего досуга. 
    </React.Fragment>
  ),
};

export default dentalDataCopy;
