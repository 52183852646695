import tour1 from "../assets/tour1.jpeg";
import tour2 from "../assets/tour2.jpeg";
import tour3 from "../assets/tour3.jpeg";
import tour4 from "../assets/tour4.jpeg";
import tour5 from "../assets/tour5.jpeg";
import tour6 from "../assets/tour6.jpeg";

const ToursData = [
  {
    description: {
      heading: "Фотосессия в стиле нидерландского жителя 17 века.",
      text: 'Давайте вместе окунёмся в Амстердам 17-го века, в ту эпоху, когда благородные дамы сжимали талии корсетами и украшали свои прически стильными париками. Именно в это время Ян Вермеер создавал свои великолепные полотна, среди которых и "Молочница", а Рембрандт вдохновлялся для воплощения романтических образов, таких как, например, Флора. Я с удовольствием расскажу вам о знаменитых героях их произведений и помогу вам стать частью одного из этих потрясающих образов.',
    },
    images: [tour1, tour3],
  },
  {
    description: {
      heading: "Прогулка на велосипедах, исследуя Амстердам вместе.",
      text: "Для тех, кто хочет окунуться в атмосферу Амстердама на велосипеде, я готова поделиться нюансами этой культуры. Наши путешествия начнутся с знакомства с особыми правилами и знаками дорожного движения. Я буду рядом, чтобы провести вас по городу, объясняя основные моменты и поделившись интересными фактами об Амстердаме. А как же без остановок? На маршруте запланированы две вкусные точки: в одной можно попробовать селедку, а в другой насладиться изысканным шоколадом. А между тем я буду рассказывать о истории города, его современной жизни и местной гастрономии.",
    },
    images: [tour2, tour4],
  },
  {
    description: {
      heading:
        "Первые моменты в Амстердаме — время полного восторга и увлекательных открытий.",
      text: "Город может оставить неповторимое впечатление, а Амстердам — тем более. Я предлагаю вам отправиться в путешествие по этой удивительной столице, чтобы она стала для вас гораздо больше, чем просто европейский город с историей и узкими улочками. Вместе мы познакомимся с теми символами, которые делают Амстердам особенным — от тюльпанов и велосипедов до каналов и знаменитых голландских сыров. Вы узнаете, где найти восхитительные места для дегустаций, что особенно стоит посмотреть на площади Дам и в известных кварталах. И напоследок, мы отправимся на пароме к самым высоким качелям в Европе!",
    },
    images: [tour5, tour6],
  },
];

export default ToursData;
